.dash-tr-header {
  padding-top: 5px !important;
  color: var(--JV-white) !important;
  background-color: var(--JV-primary) !important;
}

.ReactTable .rt-td {
  padding: 0px !important;
}
.dash-txtbox {
  background-color: var(--JV-white) !important;
  border-radius: 0 !important;
}
.dash-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 120px !important;
  border-radius: 0 !important;
}
.dash-modal-header {
  color: var(--JV-white) !important;
  background-color: var(--JV-primary) !important;
}

.cardContainerRow {
  flex-direction: column;
  height: 100% !important;
}
.cardContainerCol {
  flex-grow: 1;
  overflow: hidden;
}
.cardProjectsHeader {
  background-color: var(--JV-primary) !important;
  color: var(--JV-white) !important;
}
.modal-100w {
  max-width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
  /* height: 100vh !important; */
}
.dashboard-modal-header-text {
  color: var(--JV-primary) !important;
}
.dashboard-modal-header {
  border-bottom: var(--JV-black) solid 1px;
}
.dashboard-modal-body {
  padding-left: 30px !important;
  padding-right: 30px !important;
  height: 99vh !important;
}
.dashboard-modal {
  /* height: 100vh !important; */
}

.tableMaterials,
.tableOrders {
  height: 60vh !important;
}
.tablePhase {
  height: 50vh !important;
}
.tableProjects {
  height: 60vh !important;
}
.tableOrderHistory {
  height: 48vh !important;
}
.notifOver {
  background-color: var(--JV-warning) !important;
}
.orderTableWarnTxt {
  line-height: 35px !important;
}
.orderTableWarnCont {
  background-color: var(--JV-warning) !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.orderTableWarnContOK {
  background-color: var(--JV-tertiary) !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.btnProjectPO {
  background-color: var(--JV-primary) !important;
}
.txtDateEndCont,
.selProjFilterCont {
  margin-left: 30px !important;
}
.dashboardTabs > a.nav-link.active {
  background-color: var(--JV-primary) !important;
  color: var(--JV-white) !important;
  border-radius: 0 !important;
  border: none !important;
}
.dashboardTabs > a.nav-link:hover {
  background-color: var(--JV-tertiary) !important;
  color: var(--JV-white) !important;
  border-radius: 0 !important;
  border: none !important;
}
.dashboardTabs > a {
  color: var(--JV-black) !important;
  font-weight: bold !important;
}
@media (max-width: 409px) {
  .searchInputCont,
  .selProjFilterCont,
  .chkDateFilter,
  .txtDateStartCont {
    margin-bottom: 15px !important;
  }

  .txtDateEndCont,
  .selProjFilterCont {
    margin-left: 0px !important;
  }
  .dash-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  .dashboard-modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    height: 140vh !important;
  }
  .dashboard-modal-header-text {
    padding-left: 30px !important;
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .po-modal-header-text {
    font-size: 18px !important;
  }
}

@media (min-width: 409px) and (max-width: 575.98px) {
  .searchInputCont,
  .selProjFilterCont,
  .chkDateFilter,
  .txtDateStartCont {
    margin-bottom: 15px !important;
  }
  .txtDateEndCont,
  .selProjFilterCont {
    margin-left: 0px !important;
  }
  .dash-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  .dashboard-modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    height: 140vh !important;
  }
  .dashboard-modal-header-text {
    padding-left: 30px !important;
    font-size: 18px !important;
    line-height: 30px !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .searchInputCont,
  .selProjFilterCont,
  .chkDateFilter,
  .txtDateStartCont {
    margin-bottom: 15px !important;
  }
  .txtDateEndCont,
  .selProjFilterCont {
    margin-left: 0px !important;
  }
  .dash-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  .dashboard-modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    height: 140vh !important;
  }
  .dashboard-modal-header-text {
    padding-left: 30px !important;
    font-size: 22px !important;
    line-height: 30px !important;
  }
}
@media (max-width: 767.98px) {
  .selPo {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .selPoFilter {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .dashMaterialSearch {
    margin-bottom: 15px !important;
  }
  .btnProjectPO {
    margin-bottom: 15px !important;
  }
  .phase-tc-txtbox-name {
    font-size: 12px !important;
  }
}
@media (min-width: 767.98px) {
  .selPo {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .selPoFilter {
    padding-left: 30px !important;
  }
  .dashMaterialSearch {
    padding-right: 30px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dash-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
