.customDropCont {
}
.customDropMenu {
  width: 280px !important;
  padding: 10px 10px 10px 10px !important;
}
.customDropToggle {
  height: 100% !important;
}
.dropdown-toggle::before {
  display: none !important;
}
.tooltip-arrow {
  display: none !important;
}
.btnCustAdd {
  height: 100%;
  background-color: var(--JV-primary) !important;
}
.btnCustEdit {
  height: 100%;
  background-color: var(--JV-secondary) !important;
}
.btnCustDelete {
  height: 100%;
  background-color: var(--JV-warning) !important;
}
.btnCustSave {
  height: 100%;
  background-color: var(--JV-primary) !important;
}
.btnCustCancel {
  height: 100%;
  background-color: var(--JV-yellow) !important;
}
#txtCustDrop {
  margin: 0px !important;
}
