.project-btn-crud-col {
  flex: 1 !important;
  /* padding-left: 30% !important; */
}
.project-btn-crud {
  background-color: var(--JV-primary) !important;
  /* margin: 0px 10px 0px 10px !important; */
}
.project-btn-crud-inner-col {
  padding-left: 10px !important;
}
.btnDeleteProject {
  background-color: var(--JV-warning) !important;
}
.project-select {
  padding-left: 20px !important;
}
.permission-crud {
  padding-left: 20px !important;
}

.btnAddProject,
.projectModalBtnSave {
  background-color: var(--JV-primary) !important;
}
.btnEditProject {
  background-color: var(--JV-secondary) !important;
}
.projectModalBtnCancel {
  background-color: var(--JV-warning) !important;
}

.projectBtnViewRow {
  background-color: var(--JV-primary) !important;
}

.project--date-date {
  color: var(--JV-primary) !important;
}

.project-date-date {
  color: var(--JV-primary) !important;
}

.row-crud {
  padding: 10px 0px 10px 0px !important;
}

.ico-search {
  background-color: #fff !important;
  border-right: transparent !important;
}
.searchTable {
  border-left: transparent !important;
}
.form-control:focus {
  outline-color: #ced4da !important;
  border-color: #ced4da !important;
}
.project-modal-body {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.project-modal-header-text {
  color: var(--JV-white) !important;
}
.project-modal-header-cont {
  /* background-color: var(--JV-secondary) !important; */
}
.project-modal-header {
  width: 100% !important;
  padding-top: 10px !important;
  border-bottom: var(--JV-black) solid 1px;

  padding-bottom: 20px !important;
}
.modal-90w {
  width: 90% !important;
}
.project-modal-footer-row {
  padding-top: 20px !important;
  width: 100% !important;
}
.project-modal-footer {
  border-top: var(--JV-black) solid 1px;
}
.project-col-cont-padding {
  padding-left: 30px !important;
}
.project-col-cont-padding-left {
  padding-right: 30px !important;
}
.project-col-cont-padding-right {
  padding-left: 30px !important;
}
.project-col-innerCont-padding {
  padding-left: 30px !important;
  /* padding: 0px 30px 0px 30px !important; */
}
.cont-pw {
  background-color: rgb(248, 248, 248);
}
.project-modal-header-text {
  color: var(--JV-secondary) !important;
  font-weight: 600 !important;
}

.lblPasswordMsg {
  color: var(--JV-warning);
  line-height: 17px;
}

.project-btn-crud > * {
  pointer-events: none !important;
}

.phaseDetailedHR {
  margin-top: 0 !important;
}

.project-tr-header {
  /* padding-top: 5px !important; */
  color: var(--JV-white) !important;
  /* background-color: var(--JV-primary) !important; */
}

.loss {
  background-color: var(--JV-warning) !important;
}

.save {
  background-color: var(--JV-primary) !important;
}

/* .ReactTable .rt-tr {
  height: 100% !important;
}

.ReactTable .rt-tr {
  flex: 0 !important;
} */
.project-tc-txtbox {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  background-color: var(--JV-white) !important;
  font-weight: 600 !important;
}
.project-tc-labels {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  color: var(--JV-grey) !important;
  background-color: var(--JV-white) !important;
  width: 75px !important;
}
.project-tc-cont {
  background-color: var(--JV-white) !important;
  height: 100% !important;
}
.proj-stat-label {
  width: 100% !important;
  font-weight: 600 !important;
  white-space: initial;

  color: var(--JV-black) !important;
  height: 100% !important;
}
.project-percentage {
  border-radius: 0px !important;
  height: 100% !important;
}
.proj-stat-cont {
  padding: 5px 0px 5px 0px;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
}
.modal-project-100w {
  max-width: 100% !important;
}
