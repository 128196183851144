.phase-btn-crud > * {
  pointer-events: none !important;
}
.phase-modal-header {
  width: 100% !important;
}
.phase-modal-header-text {
  color: var(--JV-white) !important;
}

.phase-modal-header-cont {
  background-color: var(--JV-secondary) !important;
}
.material-btn-crud > * {
  pointer-events: none !important;
}
.btnAddMaterial,
.btnAddPhase,
.btnAddItem,
.modalSavePhase {
  background-color: var(--JV-primary) !important;
}
.btnEditMaterial,
.btnEditPhase {
  background-color: var(--JV-secondary) !important;
}
.btnDeleteMaterial,
.btnDeletePhase,
.modalCancelPhase {
  background-color: var(--JV-warning) !important;
}
.phase-col-cont-padding,
.phase-col-innerCont-padding {
  padding: 0px 30px 0px 30px !important;
}
.pdfPhase-select {
  padding-left: 30px !important;
}
.phase-select {
  padding-right: 30px !important;
}
.btnPhaseExport {
  background-color: var(--JV-primary) !important;
  color: white !important;
}
.btnAddPhase {
  border-radius: 0px !important;
}
.btnOpenDetails {
  background-color: var(--JV-primary) !important;
}
.phase-card-cont {
  /* height: 100% !important; */
  flex-grow: 1;
}
.tableProject {
}
.phase-card-body {
  background-color: var(--JV-white) !important;
  /* height: 100% !important; */
  /* max-height: 55vh !important; */
  /* overflow-y: auto; */
}
.phase-card-parent-cont {
  /* max-height: 50vh; */
  /* max-height: 65vh !important; */
  /* overflow-y: auto; */
  /* padding: 0px 10px 0px 10px !important; */
}
.phase-card-header {
  background-color: var(--JV-primary) !important;
  color: white !important;
}
.phase-percentage {
  height: 100% !important;
}

.page-selectedPhase-row {
  flex-grow: 1 !important;
}
.phase-cont {
  height: 100% !important;
}
.phase-col {
  /* padding: 0px 10px 0px 10px !important; */
  display: flex;
  flex-direction: column;
}

.phase-tr-header {
  /* padding-top: 5px !important; */
  color: var(--JV-white) !important;
  background-color: var(--JV-primary) !important;
}

.loss {
  color: var(--JV-warning) !important;
}

.save {
  color: var(--JV-green-light) !important;
}

.ReactTable .rt-td {
  padding: 0px !important;
}
.phase-tc-txtbox {
  border-radius: 0px !important;
  /* padding: 0px !important; */
  /* line-height: 5px !important; */
  border-color: transparent !important;
  /* height: 30px !important; */
  background-color: var(--JV-white) !important;
  font-weight: 600 !important;
  /* -webkit-box-shadow: none;
  box-shadow: none; */
}

.phase-d-card-labels {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  color: var(--JV-grey) !important;
  background-color: var(--JV-white) !important;
  width: 160px !important;
}

.phase-tc-labels {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  color: var(--JV-grey) !important;
  background-color: var(--JV-white) !important;
  width: 100px !important;
}
.phase-tc-cont {
  background-color: var(--JV-white) !important;
}
.prog-incomplete {
  background-color: #4a87e2 !important;
}
.prog-finished {
  background-color: #5db55d !important;
}
.phase-tc-txtbox-name {
  background-color: transparent !important;
  color: var(--JV-white) !important;
  border-radius: 0px !important;

  border-color: transparent !important;
}
.number-format-center {
  vertical-align: middle !important;
  display: inline !important;
}
.phase-details-txtbox {
  border-radius: 0px !important;

  border-color: transparent !important;
  height: 20px !important;
  background-color: var(--JV-white) !important;
  font-weight: 600 !important;
}
.order-modal-header-text {
  color: var(--JV-primary) !important;
}
.order-modal-header {
  border-bottom: var(--JV-black) solid 1px;
}

.order-modal-header-cont {
  background-color: var(--JV-secondary) !important;
}
.ord-bg-dark {
  background-color: rgba(0, 0, 0, 0.5);
}
.phaseTableWarnTxt {
  color: var(--JV-white) !important;
  vertical-align: sub;
}
.phaseTableWarnCont {
  background-color: var(--JV-warning) !important;
  height: auto !important;
  padding-left: 30px !important;
}
.notifOver {
  background-color: var(--JV-warning) !important;
  color: var(--JV-white) !important;
}
.btnsetFinished {
  background-color: var(--JV-primary) !important;
}
.phaseTableWPercentTxt {
  padding: 5px 15px 0px 15px !important;
  width: 80px !important;
  background-color: var(--JV-tertiary) !important;
  color: var(--JV-white) !important;
}
.modal-phase-90w {
  max-width: 100% !important;

  /* padding-left: 30px !important;
  padding-right: 30px !important; */
}

.material-modal-header {
  border-bottom: var(--JV-black) solid 1px;
}
.material-modal-footer {
  border-top: var(--JV-black) solid 1px;
}
.material-modal-header-text {
  color: var(--JV-primary) !important;
}
.btnViewOrders {
  background-color: var(--JV-primary) !important;
}

.btnMaterialDelete {
  background-color: var(--JV-warning) !important;
}
.btnMaterialSave,
.btnMaterialModify {
  background-color: var(--JV-primary) !important;
}
.material-modal-body {
  padding-right: 30px !important;
  padding-left: 30px !important;
  overflow: auto;
}
.modal-order-body {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
