.po-modal-header-cont {
  background-color: var(--JV-secondary) !important;
}
.po-modal-header-text {
  color: var(--JV-primary) !important;
}

.po-modal-header-cont {
  background-color: var(--JV-secondary) !important;
}
.po-modal-header {
  border-bottom: var(--JV-black) solid 1px;
}
.po-list-length {
  background-color: var(--JV-warning) !important;
  color: var(--JV-white) !important;
}
.modal-po-90w {
  max-width: 100% !important;
  padding: 0px !important;
}
.po-info-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 110px !important;
}

.po-tc-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 100px !important;
  border-radius: 0 !important;
  border: none !important;
  /* border-color: transparent !important; */
}
.btnRevertPO {
  background-color: var(--JV-warning) !important;
}
.btnGeneratePOApproval,
.btnPoList,
.btnSearchPO {
  background-color: var(--JV-primary) !important;
}
.po-stat-cont {
  height: 100% !important;
}
.po-modal-body {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
/* #select-all {
  display: block !important;
} */
