@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap");

:root {
  --JV-white: #fcfeff;
  --JV-bg-color: #f5f7fc;
  --JV-primary: #1e88e5;
  --JV-secondary: #005cb2;
  --JV-tertiary: #6ab7ff;
  --JV-black: #0d0d0d;
  --JV-body: #adb4c6;
  --JV-green: #4f6228;
  --JV-green-light: #45a545;

  --JV-grey: #7e8081;
  --JV-warning: #e24a4a;
  --JV-yellow: #dfe24a;
}

.btn {
  border: none !important;
}

/* .col,
.row {
  padding: 0 !important;
  margin: 0 !important;
} */

.btn-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.form-control,
.form-label {
  font-family: "Nunito", sans-serif !important;
}

input,
button,
textarea,
.btn-group,
.input-group-prepend,
.input-group-text,
select {
  border-radius: 0px !important;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.required-field {
  color: var(--JV-warning) !important;
}

.modal-body {
  background-color: var(--JV-white) !important;
}
.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination {
  box-shadow: none !important;
}

#select-all {
  display: none;
}

.modal-footer {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.global-modal-header {
  background-color: var(--JV-primary) !important;
}
.global-modal-header-title {
  color: var(--JV-white) !important;
}
.global-modal-header-title > h6 {
  font-size: 20px !important;
  font-weight: bold !important;
}
.global-modal-body > h6 {
  font-size: 18px !important;
  font-weight: bold !important;
}
.global-modal-body {
  background-color: var(--JV-white) !important;
}
.pdf-modal-dialog {
  max-width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}
.pdfViewer {
  width: 100% !important;
  height: 85vh !important;
}
