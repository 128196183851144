.dataLoading {
  position: absolute;
  margin: 0;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 30000 !important;
  background-color: rgba(151, 151, 151, 0.9) !important;
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.spinnercont {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
