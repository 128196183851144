.order-btn-crud > * {
  pointer-events: none !important;
}
.material-btn-crud > * {
  pointer-events: none !important;
}
.btnAddOrder,
.btnAddOrder,
.modalSaveOrder {
  background-color: var(--JV-primary) !important;
}
.btnEditOrder,
.btnEditOrder {
  background-color: var(--JV-secondary) !important;
}
.btnDeleteOrder,
.btnDeleteOrder,
.modalCancelOrder {
  background-color: var(--JV-warning) !important;
}
.order-col-cont-padding,
.order-col-innerCont-padding {
  padding: 0px 30px 0px 30px !important;
}
.pdfOrder-select {
  width: auto !important;
}

.ReactTable .rt-td {
  padding: 0px !important;
}

.order-info-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 80px !important;
  border-radius: 0 !important;
}
.order-lbl-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 150px !important;
  border-radius: 0 !important;
}

.order-tc-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 95px !important;
  border-radius: 0 !important;
  /* border-radius: 0 !important;
  border: none !important; */
}
.order-tc-txtbox {
  border-radius: 0px !important;
  /* padding: 0px !important; */
  /* line-height: 5px !important; */
  /* border-color: transparent !important; */
  /* height: 30px !important; */
  background-color: var(--JV-white) !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
  /* -webkit-box-shadow: none;
  box-shadow: none; */
}
/* .order-tc-txtbox {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  background-color: var(--JV-white) !important;
  font-weight: 600 !important;
}
.order-tc-labels {
  border-radius: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  color: var(--JV-grey) !important;
  background-color: var(--JV-white) !important;
  width: 80px !important;
} */
.order-tc-cont {
  background-color: var(--JV-white) !important;
}
.order-stat-label {
  width: 100% !important;
  font-weight: 600 !important;
  white-space: initial;
  margin: 0px !important;
  flex-grow: 1 !important;
  color: var(--JV-black) !important;
  height: 100% !important;
}
.order-percentage {
  border-radius: 0px !important;
  height: 80% !important;
}
.order-stat-cont {
  /* padding: 5px 0px 5px 0px;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important; */
}

.supplier-modal-header-text {
  color: var(--JV-white) !important;
}

.supplier-modal-header-cont {
  background-color: var(--JV-secondary) !important;
}
.inputFileUpload {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da;

  height: 100% !important;
  padding: 5px;
}

.btnSaveFileMultimat {
  background-color: var(--JV-primary) !important;
}
.order-tc-txtbox {
  background-color: var(--JV-white) !important;
  border-radius: 0 !important;
}

.modal-ord--90w {
  max-width: 100% !important;
  padding: 0% !important;
}
.btnAddSupplier {
  background-color: var(--JV-primary) !important;
}
.orderModalBtnSave {
  background-color: var(--JV-primary) !important;
}
.orderModalBtnDelete {
  background-color: var(--JV-warning) !important;
}
.orderModalBtnModify {
  background-color: var(--JV-primary) !important;
}
.bg-ok {
  background-color: var(--JV-secondary) !important;
  padding: 3px 8px 3px 8px !important;
  color: var(--JV-white) !important;
}
.bg-warn {
  padding: 3px 8px 3px 8px !important;
  color: var(--JV-white) !important;
  background-color: var(--JV-warning) !important;
  font-weight: bolder !important;
}
.txtSupplierInfo {
  background-color: var(--JV-white) !important;
  border-radius: 0px !important;
}
.lblSupplierInfo {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 85px !important;
  border-radius: 0px !important;
}
.btnInfoSupplier {
  border-radius: 0px !important;
  background-color: var(--JV-tertiary) !important;
}
.page-selectedPhase-col-r {
  padding-left: 10px !important;
}
.page-selectedPhase-col-l {
  padding-right: 10px !important;
}
.order-modal-footer {
  border-top: var(--JV-black) solid 1px;
}
.order-modal-body {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.btnSupplierSave {
}
