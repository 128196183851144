.actor-btn-crud-col {
  /* align-content: flex-end !important; */
  /* padding-left: 30% !important; */
}
.actor-btn-crud {
  background-color: var(--JV-primary) !important;
  /* margin: 0px 10px 0px 10px !important; */
}
.actor-btn-crud-inner-cont {
  /* align-self: flex-end !important; */
}
.actor-modal-footer {
  border-top: var(--JV-black) solid 1px;
}
.actor-modal-footer-row {
  padding-top: 20px !important;
  width: 100% !important;
}
.actor-modal-header {
  background-color: var(--JV-primary) !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.actor-modal-body {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.actor-modal-header-col {
  color: var(--JV-white) !important;
}
.btnDeleteActor {
  background-color: var(--JV-warning) !important;
}
.user-select {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.actor-permission-crud {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.btnAddActor {
  background-color: var(--JV-primary) !important;
}
.btnEditActor {
  background-color: var(--JV-secondary) !important;
}
.actorBtnViewRow {
  background-color: var(--JV-primary) !important;
}

.actor-date-date {
  color: var(--JV-primary) !important;
}

.actor-row-crud {
  padding: 10px 0px 10px 0px !important;
}

.actor-ico-search {
  background-color: #fff !important;
  border-right: transparent !important;
}
.actor-searchTable {
  border-left: transparent !important;
}
.form-control:focus {
  outline-color: #ced4da !important;
  border-color: #ced4da !important;
}
.actor-modal-header {
  width: 100% !important;
}
.modal-actor-100w {
  max-width: 100% !important;
  padding: 0px !important;
}

.css-actorInput {
  padding-right: 60px !important;
}

.user-col-cont-padding {
  padding-left: 30px !important;
  /* padding: 0px 30px 0px 30px !important; */
}
.cont-pw {
  background-color: rgb(248, 248, 248);
}
.actor-modal-header-row {
  border-bottom: var(--JV-black) solid 1px;
}
.actor-modal-header-text {
  font-weight: 600 !important;
  color: var(--JV-primary) !important;
}

.lblPasswordMsg {
  color: var(--JV-warning);
  line-height: 17px;
}
.actor-btn-crud > * {
  pointer-events: none !important;
}
/* Mike */

.rt-td {
  align-self: center;
}
