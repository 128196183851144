.workspaceContainer {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}
.stage-container {
  height: 100% !important;
  max-height: 100% !important;
}
.stage {
  z-index: 0;
  width: auto;
  height: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  background-color: var(--JV-white) !important;
}
.nav-item > a {
  color: var(--JV-white) !important;
}

.nav-item > a.nav-link.active {
  background-color: var(--JV-tertiary) !important;
}

.btnDashLink {
  font-weight: 800;
}

.navbar,
.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}
.navbar-brand {
  align-self: center;
}

.workspace-nav {
  width: auto !important;
  background-color: var(--JV-primary) !important;
}

.navWorkspace {
  width: 100% !important;
  background-color: var(--JV-primary) !important;
  /* padding: 20px 0px 20px 0px !important; */
  /* -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75); */
}

.workspaceLogoCont {
  cursor: pointer;
}
.workspace-logo {
  max-width: 25%;
  max-height: 100%;
}
.btnLogout {
  background-color: var(--JV-warning) !important;
  /* margin-right: 40px; */
}
.btnProfile {
  background-color: transparent !important;
  text-decoration: underline;
  outline: none !important;
  -webkit-box-shadow: none !important;
}
.top-nav-cont {
  padding: 15px !important;
  width: 100%;
}
@media (max-width: 409px) {
  .workspace-logo {
    width: 60% !important;
  }
  .workspace-brand-cont {
    padding-left: 30px !important;
  }
  .stage {
    padding: 30px 0px 0px 0px;
  }
}

@media (min-width: 409px) and (max-width: 575.98px) {
  .workspace-brand-cont {
    padding-left: 70px !important;
  }
  .stage {
    padding: 30px 0px 30px 0px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .workspace-brand-cont {
    padding-left: 130px !important;
  }
  .workspace-logo {
    width: 30% !important;
  }
  .stage {
    padding: 30px 0px 30px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .workspace-brand-cont {
    padding-left: 140px !important;
  }
  .workspace-logo {
    width: 25% !important;
  }
  .stage {
    padding: 30px 0px 30px 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navWorkspace {
    padding-right: 15px !important;
  }

  .top-nav-cont {
    padding: 15px !important;
    width: 25% !important;
  }
  .workspace-links {
    font-size: 10px !important;
  }
  .btnProfile {
    font-size: 10px !important;
  }
  .btnLogout {
    font-size: 10px !important;
  }
  .stage {
    padding: 30px 40px 30px 40px;
  }
}

@media (min-width: 1200px) {
  .navWorkspace {
    padding-right: 15px !important;
  }
  .workspace-logo {
    width: 60% !important;
  }
  .top-nav-cont {
    padding: 15px !important;
    width: 30% !important;
  }
  .stage {
    padding: 30px 100px 30px 100px;
  }
}
@media (max-width: 1145px) {
  /* .workspace-logo {
    width: 80% !important;
  }
  .nav-workspace-links {
   
  }
  .workspace-links {
    font-size: 10px !important;
  }
  .btnProfile {
   
    font-size: 10px !important;
  }
  .btnLogout {
    font-size: 10px !important;
  } */
}
