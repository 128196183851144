p {
  margin: 0 !important;
}

.row-content {
  width: 100%;
  height: 100vh;
}

.col-login {
  flex-direction: column;
  display: flex;
  background-color: var(--JV-white) !important;
  -webkit-box-shadow: -5px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 0px 10px -4px rgba(0, 0, 0, 0.5);
}
.form-login-container {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.form-login-welcome {
  padding-bottom: 100px;
}
.form-login {
  align-self: center;
  width: 100%;
}
.span-login-welcome {
  font-weight: 600;
}
.span-login-firmName {
  font-weight: 900;
}
.row-login-footer {
  flex: 1;
}

.lblLogin {
  font-size: 34px;
}
.btnLogin {
  background-color: var(--JV-primary) !important;
  -webkit-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.5);
}

.row-login-footer > p {
  color: var(--JV-body) !important;
}

.landing-illu {
  max-width: 100%;
  max-height: 100%;
}

.IlluContainer {
  display: flex;
  height: 100%;
}

.LogoContainer {
  display: flex;
  justify-content: center;
}

.landing-logo {
  flex: 1;
  padding-top: 100px;
}

@media (max-width: 991px) {
  .logoContainer {
    padding-left: 70px;
    padding-right: 70px;
    /* height: 30%; */
  }
  .landing-logo {
    width: 50%;
  }
  .landingContainer {
    height: 0 !important;
    width: 0 !important;
  }
  .form-login-welcome {
    display: none;
  }
}
@media (min-width: 992px) {
  .landing-logo {
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
