.profile-bg {
  /* padding: 50px 250px; */
}
.profile-card-cont {
  width: 100%;
}
.profile-card-header {
  background-color: var(--JV-primary) !important;
  color: white !important;
}
.profile-logo {
  font-size: 100px;
}
.profile-items-wrapper {
  margin: auto;
}
.profile-item-container {
  /* border: 1px solid #c9c9c9;
  border-radius: 10px; */
  height: 50px;
  margin: 10px 0px;
}
.profile-d-card-labels {
  margin-left: 10px !important;
  /* color: var(--JV-grey) !important;
  background-color: var(--JV-white) !important; */
  width: 80px !important;
  border: none !important;
}
.profile-details-txtbox {
  height: 100% !important;
  background-color: var(--JV-white) !important;
}
