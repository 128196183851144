.verification-tr-header {
  padding-top: 5px !important;
  color: var(--JV-white) !important;
  background-color: var(--JV-primary) !important;
}

.ReactTable .rt-td {
  padding: 0px !important;
}
.verification-tc-txtbox {
  background-color: var(--JV-white) !important;
  border-radius: 0 !important;
}
.verification-tc-labels {
  color: var(--JV-black) !important;
  background-color: var(--JV-white) !important;
  width: 120px !important;
  border-radius: 0 !important;
}

.tableContainerRow {
  flex-direction: column;
  height: 100% !important;
}
.tableContainerCol {
  flex-grow: 1;
  overflow: hidden;
}
.tableVerification {
  max-height: 100% !important;
}
.modal-ver-90w {
  max-width: 100% !important;
  padding: 0px !important;
}

.loss {
  color: var(--JV-warning) !important;
}

.save {
  color: var(--JV-primary) !important;
}
.verif-modal-header-text {
  color: var(--JV-primary) !important;
}
.verif-modal-header {
  /* background-color: var(--JV-primary) !important; */
  border-bottom: var(--JV-black) solid 1px;
}
.verif-modal-footer {
  border-top: var(--JV-black) solid 1px;
}

@media (max-width: 409px) {
  .verif-modal-header-text {
    font-size: 20px !important;
  }
  .verif-modal-footer {
    justify-content: center !important;
  }
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    width: 100% !important;
  }
  .verification-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  /* .verif-modal-header {
    padding-right: 20px !important;
    padding-left: 20px !important;
  } */
}

@media (min-width: 409px) and (max-width: 575.98px) {
  .verif-modal-footer {
    justify-content: center !important;
  }
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    width: 100% !important;
  }
  /* .verification-header-cont {
    padding: 0px 20px 20px 20px !important;
  } */
  /* .verif-modal-header {
    padding-right: 20px !important;
    padding-left: 20px !important;
  } */
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .verif-modal-footer {
    justify-content: center !important;
  }
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    width: 100% !important;
  }
  /* .verification-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  .verif-modal-header {
    padding-right: 20px !important;
    padding-left: 20px !important;
  } */
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ver-modal-body-cols-right {
    padding-left: 10px !important;
  }
  .ver-modal-body-cols-left {
    padding-right: 10px !important;
  }
  .verif-modal-body {
    padding: 0px 40px 0px 40px !important;
  }
  /* .verif-modal-footer {
    padding: 0px 40px 0px 40px !important;
  } */
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    margin-left: 20px !important;
    /* width: 130px !important;
    margin-right: 30px !important; */
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    margin-left: 20px !important;
    /* width: 130px !important;
    margin-left: 30px !important; */
  }
  /* .verification-header-cont {
    padding: 0px 20px 20px 20px !important;
  }
  .verif-modal-header {
    padding-right: 40px !important;
    padding-left: 40px !important;
  } */
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ver-modal-body-cols-right {
    padding-left: 20px !important;
  }
  .ver-modal-body-cols-left {
    padding-right: 20px !important;
  }
  .verif-modal-body {
    padding: 0px 60px 0px 60px !important;
  }
  /* .verif-modal-footer {
    padding: 0px 60px 0px 60px !important;
  } */
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    margin-left: 30px !important;
    /* width: 130px !important;
    margin-right: 30px !important; */
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    margin-left: 30px !important;
    /* width: 130px !important;
    margin-left: 30px !important; */
  }
  /* .verif-modal-header {
    padding-right: 60px !important;
    padding-left: 60px !important;
  } */
}

@media (min-width: 1200px) {
  .ver-modal-body-cols-right {
    padding-left: 30px !important;
  }
  .ver-modal-body-cols-left {
    padding-right: 30px !important;
  }
  .verif-modal-body {
    padding: 0px 100px 0px 100px !important;
  }
  /* .verif-modal-footer {
    padding: 0px 100px 0px 100px !important;
  } */
  .btnVarConfirm {
    background-color: var(--JV-primary) !important;
    /* width: 130px !important;
    margin-right: 30px !important; */
    margin-left: 30px !important;
  }
  .btnVarDeny {
    background-color: var(--JV-warning) !important;
    /* width: 130px !important;
    margin-left: 30px !important; */
    margin-left: 30px !important;
  }
  /* .verif-modal-header {
    padding-right: 100px !important;
    padding-left: 100px !important;
  } */
}
